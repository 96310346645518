import React, { useEffect, useState, useRef, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

// Helper function to dynamically import icons
const loadIcon = (iconName) => {
  try {
    const formattedIconName = `fa${iconName.charAt(0).toUpperCase() + iconName.slice(1)}`;
    return Icons[formattedIconName] || null;
  } catch (error) {
    console.warn(`Icon "${iconName}" not found.`);
    return null;
  }
};

const Statistics = () => {
  const [counts, setCounts] = useState({});
  const [statistics, setStatistics] = useState({});
  const [loadedIcons, setLoadedIcons] = useState({});
  const statSectionRef = useRef(null);
  const isAnimatingRef = useRef(false); // Ref to track animation state

  // Fetch data from JSON files
  useEffect(() => {
    const fetchData = async () => {
      try {
        const statsResponse = await fetch('./assets/uploads/data/statistics.json');
        const statsData = await statsResponse.json();

        const productsResponse = await fetch('./assets/uploads/data/products.json');
        const productsData = await productsResponse.json();

        const productCount = productsData.length - 1;
        if (statsData.availableProducts) {
          statsData.availableProducts.score.value = productCount;
        }

        const currentYear = new Date().getFullYear();
        Object.keys(statsData).forEach((key) => {
          const item = statsData[key];
          if (key === "yearsInOperation" && item.score.baseYear) {
            const yearsInOperation = currentYear - item.score.baseYear;
            item.score.value = yearsInOperation;
          }
        });

        setStatistics(statsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Load icons dynamically
  useEffect(() => {
    if (Object.keys(statistics).length > 0) {
      const loadAllIcons = async () => {
        const icons = {};
        const iconPromises = Object.keys(statistics).map(async (key) => {
          const iconName = statistics[key].icon;
          if (iconName) {
            const icon = await loadIcon(iconName);
            icons[iconName] = icon;
          }
        });

        await Promise.all(iconPromises);
        setLoadedIcons(icons);
      };

      loadAllIcons();
    }
  }, [statistics]);

  // Count animation using requestAnimationFrame for smoother results
  const animateCount = (finalValue, key) => {
    if (isNaN(finalValue)) {
      setCounts((prevCounts) => ({
        ...prevCounts,
        [key]: finalValue,
      }));
      return;
    }

    const duration = 2000;
    const startTime = performance.now();

    const step = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      const currentValue = Math.ceil(progress * finalValue);

      setCounts((prevCounts) => ({
        ...prevCounts,
        [key]: currentValue,
      }));

      if (progress < 1) {
        requestAnimationFrame(step);
      }
    };

    requestAnimationFrame(step);
  };

  // Start animation when section is in view
  const startAnimation = useCallback(() => {
    if (!isAnimatingRef.current && Object.keys(statistics).length > 0) {
      Object.keys(statistics).forEach((key) => {
        const scoreValue = statistics[key].score.value === "Not Available"
          ? "Not Available"
          : isNaN(statistics[key].score.value)
            ? statistics[key].score.value
            : parseInt(statistics[key].score.value, 10);
        animateCount(scoreValue, key);
      });
      isAnimatingRef.current = true;
    }
  }, [statistics]);

  // Reset count values
  const resetCounts = useCallback(() => {
    const resetValues = {};
    Object.keys(statistics).forEach((key) => {
      resetValues[key] =
        typeof statistics[key].score.value === "number" ? 0 : statistics[key].score.value;
    });
    setCounts(resetValues);
    isAnimatingRef.current = false;
  }, [statistics]);

  // IntersectionObserver to trigger animation on view
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && document.hasFocus()) {
          startAnimation();
        } else {
          resetCounts();
        }
      },
      { threshold: 0.1 }
    );

    const sectionRef = statSectionRef.current;
    if (sectionRef) {
      observer.observe(sectionRef);
    }

    // Cleanup event listeners and observers
    const handleFocus = () => {
      if (sectionRef) {
        startAnimation();
      }
    };

    const handleBlur = () => {
      resetCounts();
    };

    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      if (sectionRef) {
        observer.unobserve(sectionRef);
      }
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, [statistics, startAnimation, resetCounts]);

  return (
    <section id="statistics" className="content bg-light" ref={statSectionRef}>
      <div className="container">
        <h2>The number says it all</h2>
        <div className="row text-center">
          {Object.keys(statistics).map((key) => {
            const { label, icon, score } = statistics[key];
            if (!score || !icon) return null; // Skip if score or icon is missing

            const IconComponent = loadedIcons[icon];

            return (
              <div className="col-md-3 stat" key={key}>
                {IconComponent ? (
                  <FontAwesomeIcon icon={IconComponent} className="icon-custom mb-3" size="3x" />
                ) : (
                  <p>Icon not available</p>
                )}
                <h3>
                  {counts[key]}
                  {statistics[key].score.suffix}
                </h3>
                <p>{label}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Statistics;