import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBalanceScale, faCalendarAlt, faChartLine, faIndustry, faHeadset, faFileInvoice, faTruck } from '@fortawesome/free-solid-svg-icons';

const About = () => {
    const [showMore, setShowMore] = useState(false);

    const handleReadMore = () => {
        setShowMore(!showMore);
    };

    const handleNavClick = (targetId) => {
        const section = document.getElementById(targetId);
        if (section) {
            const offsetTop = section.offsetTop - 80; // Adjust for footer height if necessary
            window.scrollTo({
                top: offsetTop,
                behavior: 'smooth'
            });
        }
    };

    return (
        <section id="about" className="content">
            <div className="container">
                <div className="about-wrapper">
                    {/* Left Content */}
                    <div className="about-left">
                        <h4>Independent India</h4>
                        <h1>Your Trusted Partner in Electrical Goods</h1>
                        <p>
                            Established in 2023, Independent India is the leading manufacturer of Electrical Goods such
                            as Exhaust Fans, Air Circular Fans, Axial Flow Fans, Wall Brackets, Mancooler Blades, Tubal
                            Fans, Bullet Fans, Mancooler Fans, and all their spare parts. We are involved in
                            manufacturing a broad plethora of Industrial Axial Flow Fans, Industrial Wall Mounted Fans,
                            Industrial Pedestal Fans, Industrial Induction Motors, and Industrial Fan Blades.
                            <span style={{ display: showMore ? 'inline' : 'none' }}>
                                Our commitment to quality ensures that only top-notch materials are used by our
                                professionals, along with ultra-modern tools and machinery. Every product is thoroughly
                                checked before being shipped to our customers.
                            </span>
                            <button
                                type='button' className="read-more btn btn-link link-success p-0"
                                onClick={handleReadMore}
                                style={{ cursor: 'pointer' }}
                            >
                                {showMore ? 'Read Less' : 'Read More'}
                            </button>
                        </p>
                        <div className="text-center">
                            <button className="cta-btn2 btn btn-success d-inline-flex align-items-center mx-auto m-4" onClick={() => handleNavClick('contact')}><FontAwesomeIcon icon={faHeadset} size="1x" />&nbsp;Contact Us</button>
                        </div>
                    </div>
                    {/* Right Content */}
                    <div className="about-right">
                        <div className="about-details">
                            <div className="detail-item">
                                <FontAwesomeIcon icon={faIndustry} className="icon-custom" size="1x" />
                                <span>Nature of Business: <br /><span>Manufacturer</span></span>
                            </div>
                            <div className="detail-item">
                                <FontAwesomeIcon icon={faCalendarAlt} className="icon-custom" size="1x" />
                                <span>Year of Establishment: <br /><span>2023</span></span>
                            </div>
                            <div className="detail-item">
                                <FontAwesomeIcon icon={faChartLine} className="icon-custom" size="1x" />
                                <span>Annual Revenue: <br /><span>&#8377;</span><span>60 Lakhs in FY <span id="year">{new Date().getFullYear() - 1}</span></span></span>
                            </div>
                            <div className="detail-item">
                                <FontAwesomeIcon icon={faBalanceScale} className="icon-custom" size="1x" />
                                <span>Legal Status of Firm: <br /><span>Partnership</span></span>
                            </div>
                            <div className="detail-item">
                                <FontAwesomeIcon icon={faFileInvoice} className="icon-custom" size="1x" />
                                <span>GST Number: <br /><span>19AAKFI2952D1ZC</span></span>
                            </div>
                            <div className="detail-item">
                                <FontAwesomeIcon icon={faTruck} className="icon-custom" flip="horizontal" size="1x" />
                                <span>Comprehensive Service Network</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;