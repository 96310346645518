import React, { useState, useEffect } from 'react';
import { Offcanvas, Navbar, Nav, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

function NavigationBar() {
  const [show, setShow] = useState(false);
  const [activeSection, setActiveSection] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Function to update the active section based on scroll position
  const updateActiveSection = () => {
    const sections = document.querySelectorAll('section');
    const scrollPosition = window.scrollY + 100; // Offset for navbar height

    let currentSection = '';

    sections.forEach((section) => {
      const sectionTop = section.offsetTop;
      const sectionBottom = sectionTop + section.offsetHeight;

      // Check if the current scroll position is within this section's range
      if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
        currentSection = section.id;
      }
    });

    setActiveSection(currentSection);
  };

  // Attach the scroll event listener on mount, and clean up on unmount
  useEffect(() => {
    window.addEventListener('scroll', updateActiveSection);
    return () => window.removeEventListener('scroll', updateActiveSection);
  }, []);

  // Handle navigation button clicks with smooth scroll
  const handleNavClick = (targetId) => {
    const section = document.getElementById(targetId);
    if (section) {
      const offsetTop = section.offsetTop - 80; // Adjust for navbar height
      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      });
      setShow(false);
    }
  };

  return (
    <Navbar expand="lg" variant="dark" sticky="top" className="px-3 navbar">
      <Navbar.Brand className="mb-0 mt-0 text-white">Independent India</Navbar.Brand>

      {/* Navbar Toggle Button for Offcanvas */}
      <Button variant="primary" onClick={handleShow} className="navbar-toggler">
        <FontAwesomeIcon icon={faBars} className="custom-nav-button" />
      </Button>


      {/* Main Navbar Buttons for larger screens */}
      <Navbar.Collapse className="justify-content-end">
        <Nav className="ml-auto d-none d-lg-flex">
          <Button
            variant="link"
            onClick={() => handleNavClick('about')}
            className={`text-white nav-button ${activeSection === 'about' ? 'active' : ''}`}
          >
            About Us
          </Button>
          <Button
            variant="link"
            onClick={() => handleNavClick('products')}
            className={`text-white nav-button ${activeSection === 'products' ? 'active' : ''}`}
          >
            Products
          </Button>
          <Button
            variant="link"
            onClick={() => handleNavClick('reviews')}
            className={`text-white nav-button ${activeSection === 'reviews' ? 'active' : ''}`}
          >
            Reviews
          </Button>
          <Button
            variant="link"
            onClick={() => handleNavClick('statistics')}
            className={`text-white nav-button ${activeSection === 'statistics' ? 'active' : ''}`}
          >
            Statistics
          </Button>
          <Button
            variant="link"
            onClick={() => handleNavClick('contact')}
            className={`text-white nav-button ${activeSection === 'contact' ? 'active' : ''}`}
          >
            Contact Us
          </Button>
        </Nav>
      </Navbar.Collapse>

      {/* Offcanvas for smaller screens */}
      <Offcanvas show={show} onHide={handleClose} placement="end" className="stylish-offcanvas bg-dark text-white">
        <Offcanvas.Header>
          <Offcanvas.Title id="offcanvasNavbarLabel">Menu</Offcanvas.Title>
          <Button variant="link" onClick={handleClose} className="custom-close-button">
            <FontAwesomeIcon icon={faTimes} className="custom-close-icon" />
          </Button>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="nav-links">
            {['about', 'products', 'reviews', 'statistics', 'contact'].map((section) => (
              <button
                key={section}
                onClick={() => handleNavClick(section)}
                className={`nav-link-button ${activeSection === section ? 'active' : ''}`}
              >
                {section.charAt(0).toUpperCase() + section.slice(1).replace('_', ' ')}
              </button>
            ))}
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </Navbar>
  );
}

export default NavigationBar;