import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowRight, faEnvelope, faMapMarkerAlt, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faInstagram, faLinkedinIn, faWhatsapp, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import PolicyModal from './PolicyModal';

const Footer = () => {
    const [products, setProducts] = useState([]);
    const [showTermsModal, setShowTermsModal] = useState(false);
    const [showPrivacyModal, setShowPrivacyModal] = useState(false);

    // Fetch products from the products.json file
    useEffect(() => {
        fetch('./assets/uploads/data/products.json') // Change this path to the actual path of your products.json
            .then(response => response.json())
            .then(data => {
                setProducts(data.slice(0, 3)); // Load 6 products for footer
            })
            .catch(error => console.error("Error fetching the products:", error));
    });

    // Handle smooth scrolling to sections
    const handleNavClick = (targetId) => {
        const section = document.getElementById(targetId);
        if (section) {
            const offsetTop = section.offsetTop - 80; // Adjust for footer height if necessary
            window.scrollTo({
                top: offsetTop,
                behavior: 'smooth'
            });
        }
    };

    return (
        <footer className="pt-4 pb-2 bg-dark footer" data-bs-theme="dark">
            <div className="footer-content">
                <div className="text-center">
                    <button
                        rel="nofollow"
                        id="footer-btp"
                        style={{ border: '2px solid white', padding: '5px', color: 'black', fontWeight: 'bold', borderRadius: '5px' }}
                        className="bg-light d-inline-flex align-items-center mx-auto"
                        aria-hidden="true"
                        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    >
                        <FontAwesomeIcon icon={faArrowUp} />&nbsp;Back to top
                    </button>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 mb-3 footer-section">
                            <h5>Company</h5>
                            <ul className="footer-links">
                                <li><button className="text-white ocl" onClick={() => handleNavClick('about')} style={{ background: "none", border: "none" }}>About Us</button></li>
                                <li><button className="text-white ocl" onClick={() => handleNavClick('products')} style={{ background: "none", border: "none" }}>Our Products</button></li>
                                <li><button className="text-white ocl" onClick={() => handleNavClick('reviews')} style={{ background: "none", border: "none" }}>Testimonial</button></li>
                                <li className="d-none"><button className="text-white ocl" onClick={() => handleNavClick('corvideo')} style={{ background: "none", border: "none" }}>Corporate Video</button></li>
                                <li className="d-none"><button className="text-white ocl" onClick={() => handleNavClick('corbrochure')} style={{ background: "none", border: "none" }}>Corporate Brochure</button></li>
                                <li><a href="sitemap.xml" rel="noreferrer" target="_blank" className="text-white">Sitemap</a></li>
                                <li><button className="text-white ocl" onClick={() => handleNavClick('contact')} style={{ background: "none", border: "none" }}>Contact Us</button></li>
                                <li><button className="text-white ocl" onClick={() => setShowTermsModal(true)} style={{ background: "none", border: "none" }}>Terms of Use</button></li>
                                <li><button className="text-white ocl" onClick={() => setShowPrivacyModal(true)} style={{ background: "none", border: "none" }}>Privacy Policy</button></li>
                            </ul>
                        </div>
                        <div className="col-md-5 mb-3 footer-section">
                            <h5>Our Products</h5>
                            <ul className="footer-links" id="footer-product-links">
                                {products.map((product, index) => (
                                    <li key={index}>
                                        <button className="text-white ocl" onClick={() => handleNavClick(product.id)} style={{ background: "none", border: "none" }}>{product.name}</button>
                                    </li>
                                ))}
                                {products.length > 0 && (
                                    <li><button className="text-white ocl" onClick={() => handleNavClick('products')} style={{ background: "none", border: "none" }}>View More <FontAwesomeIcon icon={faArrowRight} className="icon-custom" /></button></li>
                                )}
                            </ul>
                        </div>
                        <div className="col-md-4 mb-3 footer-section">
                            <h5>Get in Touch</h5>
                            <ul className="contact-info">
                                <li><FontAwesomeIcon icon={faMapMarkerAlt} className="icon-custom" /><a href="https://maps.app.goo.gl/JM8wn7JqUmbSxNJ27" rel="noreferrer" target="_blank">10/C, Bepari Tola Lane, Behind Hind Cinema, Chandni Chawk, Bowbazar, Kolkata, West Bengal 700072</a></li>
                                <li><FontAwesomeIcon icon={faPhone} className="icon-custom" /><a href="https://wa.me/+918100991357" rel="noreferrer" target="_blank">+91 81009 91357</a></li>
                                <li><FontAwesomeIcon icon={faEnvelope} className="icon-custom" /><a href="mailto:support@independentindia.in">support@independentindia.in</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 footer-section">
                            <h5 className="text-center">Follow us on:</h5>
                            <ul className="social-icons">
                                <li className="list-inline-item bg-success"><a href="https://wa.me/+918100991357" rel="noreferrer" target="_blank" className="text-white"><FontAwesomeIcon icon={faWhatsapp} size="2x" /></a></li>
                                <li className="list-inline-item bg-danger"><a href="https://www.instagram.com/independentindia.in" rel="noreferrer" target="_blank" className="text-white"><FontAwesomeIcon icon={faInstagram} size="2x" /></a></li>
                                <li className="list-inline-item bg-primary"><a href="https://www.facebook.com/people/Independent-India/61565495714203/" rel="noreferrer" target="_blank" className="text-white"><FontAwesomeIcon icon={faFacebookF} size="2x" /></a></li>
                                <li className="list-inline-item bg-primary"><a href="https://in.linkedin.com/" rel="noreferrer" target="_blank" className="text-white"><FontAwesomeIcon icon={faLinkedinIn} size="2x" /></a></li>
                                <li className="list-inline-item bg-dark"><a href="https://x.com/" rel="noreferrer" target="_blank" className="text-white"><FontAwesomeIcon icon={faXTwitter} size="2x" /></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="text-center mt-3 text-white info-panel">
                        <p>&copy; <span id="year-info">{new Date().getFullYear()}</span> Independent India. All Rights Reserved.</p>
                        <p>This website is developed, hosted, and maintained by <a href="mailto:kaniska@independentindia.in" style={{ color: 'white', textDecoration: 'none' }}>Kaniska Jaiswal</a></p>
                    </div>
                </div>
            </div>
            {/* Terms of Use Modal */}
            <PolicyModal
                show={showTermsModal}
                onHide={() => setShowTermsModal(false)}
                type="termsofuse"
            />
            {/* Privacy Policy Modal */}
            <PolicyModal
                show={showPrivacyModal}
                onHide={() => setShowPrivacyModal(false)}
                type="privacypolicy"
            />
        </footer>
    );
};

export default Footer;