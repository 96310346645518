import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported

const LoadingSpinner = () => {
    return (
        <div id="loading-wrapper" style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className="text-center">
                <div className="spinner-border text-success" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <h3 className="mt-3 text-success">Loading, Please Wait...</h3>
            </div>
        </div>
    );
};

export default LoadingSpinner;