import React, { useEffect, useState } from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';

const ProductSchema = () => {
    const [products, setProducts] = useState([]); // Ensure it's initialized to an empty array
    const [loading, setLoading] = useState(true);  // Track loading state
    const [error, setError] = useState(null); // Track any errors during the fetch

    // Fetch products data from products.json
    useEffect(() => {
        fetch('./assets/uploads/data/products.json')
            .then(response => response.json())
            .then(data => {
                setProducts(data);  // Set products directly from the JSON array
                setLoading(false);   // Mark loading as complete
            })
            .catch(error => {
                setError(error);     // Set error if something goes wrong
                setLoading(false);   // Mark loading as complete even if there's an error
            });
    }, []); // This ensures the fetch happens once when the component mounts

    // Check if products are available before using map
    if (loading) {
        return <div>Loading...</div>; // Show loading indicator if still fetching
    }

    if (error) {
        return <div>Error loading products: {error.message}</div>; // Show error if fetch fails
    }

    // Ensure products data is valid before mapping
    const productSchemas = products.map((product) => {
        let priceInfo = {};

        if (product.price === 0) {
            priceInfo = {
                "@type": "AggregateOffer",
                "url": "https://independentindia.in",
                "priceCurrency": "INR",
                "lowPrice": product.lowPrice,
                "highPrice": product.highPrice
            };
        } else {
            priceInfo = {
                "@type": "Offer",
                "url": "https://independentindia.in",
                "priceCurrency": "INR",
                "price": product.price
            };

            if (product.priceValidUntil) {
                priceInfo.priceValidUntil = product.priceValidUntil;
            }
            if (product.availability) {
                priceInfo.availability = product.availability;
            }
            if (product.itemCondition) {
                priceInfo.itemCondition = product.itemCondition;
            }
        }

        const origin = window.location.origin;
        const pathname = window.location.pathname;
        const basePath = pathname.substring(0, pathname.lastIndexOf('/') + 1);
        const fullUrl = origin + basePath;

        return {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": product.name,
            "image": `${fullUrl}${product.images[0].replace('./', '')}`,
            "description": product.description,
            "brand": {
                "@type": "Brand",
                "name": "Independent India"
            },
            "sku": product.id,
            "offers": priceInfo
        };
    });

    return (
        <HelmetProvider>
            <Helmet>
                {productSchemas.map((schema, index) => (
                    <script key={index} type="application/ld+json">
                        {JSON.stringify(schema)}
                    </script>
                ))}
            </Helmet>
        </HelmetProvider>
    );
};

export default ProductSchema;