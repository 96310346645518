import React, { useState, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import NavigationBar from './components/NavigationBar';
import Footer from './components/Footer';
import Products from './components/Products';
import Reviews from './components/Reviews';
import About from './components/About';
import Contact from './components/Contact';
import Statistics from './components/Statistics';
import OrganizationSchema from './components/OrganizationSchema';
import ProductSchema from './components/ProductSchema';
import LoadingSpinner from './components/LoadingSpinner'; // Importing the LoadingSpinner
import './assets/styles/index.css';

function App() {
  const [isLoading, setIsLoading] = useState(true); // State to track loading

  useEffect(() => {
    // Simulating a loading delay (e.g., for fetching data)
    const timer = setTimeout(() => setIsLoading(false), 2000); // Adjust duration as needed

    // Cleanup timer to avoid memory leaks
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className='App'>
      {isLoading ? ( // Show the spinner while loading
        <LoadingSpinner />
      ) : (
        <HelmetProvider>
          <OrganizationSchema />
          <ProductSchema />
          <NavigationBar />
          <About />
          <Products />
          <Reviews />
          <Statistics />
          <Contact />
          <Footer />
        </HelmetProvider>
      )}
    </div>
  );
}

export default App;