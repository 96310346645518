import React, { useState, useEffect, useRef, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faEnvelope, faGlobeAsia, faMapMarkerAlt, faPaperPlane, faPhone } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    subject: "",
    comments: "",
    turnstileToken: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const captchaRef = useRef(null);
  const [captchaLoaded, setCaptchaLoaded] = useState(false);

  // Unified error handler
  const handleError = (error) => {
    console.error("Error caught by handleError:", error);

    Swal.fire({
      title: "Error!",
      text:
        error.message === "CAPTCHA_ERROR"
          ? "CAPTCHA could not be verified. Please try again."
          : "Something went wrong with CAPTCHA. Please try again later.",
      icon: "error",
      confirmButtonText: "OK",
      confirmButtonColor: "#d33",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
  };

  // Load Turnstile script
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js";
    script.async = true;
    script.defer = true;
    script.id = "turnstileScript";

    script.onload = () => setCaptchaLoaded(true);
    script.onerror = () => handleError(new Error("CAPTCHA script failed to load."));

    document.body.appendChild(script);
    return () => {
      document.getElementById("turnstileScript")?.remove();
    };
  }, []);

  // Render CAPTCHA with error handling
  const renderCaptcha = useCallback(() => {
    if (window.turnstile && captchaRef.current) {
      try {
        window.turnstile.render(captchaRef.current, {
          sitekey: process.env.NODE_ENV === "development" ? "1x00000000000000000000AA" : "0x4AAAAAAAh_AGvoiS5WvTNT",
          theme: "light",
          callback: (token) => {
            setFormData((prev) => ({ ...prev, turnstileToken: token }));
            setIsCaptchaValid(true);
          },
          "error-callback": () => {
            handleError(new Error("CAPTCHA_ERROR"));
            setIsCaptchaValid(false);
          },
          "expired-callback": () => {
            setFormData((prev) => ({ ...prev, turnstileToken: "" }));
            setIsCaptchaValid(false);
          },
        });
      } catch (error) {
        handleError(error);
      }
    }
  }, [captchaRef, setFormData, setIsCaptchaValid]);;

  useEffect(() => {
    if (captchaLoaded) {
      renderCaptcha();
    }
  }, [captchaLoaded, renderCaptcha]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value.trim() ? "" : `${name} is required.`,
    }));
  };

  const validateForm = () => {
    const errors = {};
    Object.entries(formData).forEach(([key, value]) => {
      if (!value.trim() && key !== "turnstileToken") {
        errors[key] = `${key} is required.`;
      }
    });
    setFormErrors(errors);
    return errors;
  };

  const isFormValid = () => {
    const hasErrors = Object.values(formErrors).some((error) => error);
    const areFieldsFilled = Object.values(formData).every(
      (value) => value.trim() !== ""
    );
    return areFieldsFilled && isCaptchaValid && !hasErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length > 0 || !isCaptchaValid) {
      Swal.fire({
        title: "Error!",
        text: "Please fill in all required fields and complete the CAPTCHA.",
        icon: "error",
        confirmButtonText: "OK",
        confirmButtonColor: "#d33",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
      return;
    }

    setIsSubmitting(true);
    setTimeout(() => {
      setIsSubmitting(false);
      Swal.fire({
        title: "Success!",
        text: "Your enquiry was submitted successfully!",
        icon: "success",
        confirmButtonText: "OK",
        confirmButtonColor: "#28a745",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
      resetForm();
    }, 2000);
  };

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      number: "",
      subject: "",
      comments: "",
      turnstileToken: "",
    });
    setFormErrors({});
    setIsCaptchaValid(false);

    if (window.turnstile && captchaRef.current) {
      try {
        window.turnstile.reset(captchaRef.current);
      } catch (error) {
        handleError(error);
      }
    }
  };

  return (
    <section id="contact" className="content">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="contact-form row no-gutters">
              <div className="col-md-5 info">
                <h4>Let's get in touch</h4>
                <p>If you have any questions, feel free to reach out to us. We are always here to assist you.</p>
                <p>
                  <a href="https://maps.app.goo.gl/JM8wn7JqUmbSxNJ27" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
                    <strong><FontAwesomeIcon icon={faMapMarkerAlt} /></strong> 10/C, Bepari Tola Lane, Behind Hind Cinema, Chandni Chawk, Kolkata, West Bengal 700072
                  </a>
                </p>
                <p>
                  <a href="https://wa.me/+918100991357" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
                    <strong><FontAwesomeIcon icon={faPhone} /></strong> +91 81009 91357
                  </a>
                </p>
                <p>
                  <a href="mailto:support@independentindia.in" style={{ textDecoration: 'none', color: 'white' }}>
                    <strong><FontAwesomeIcon icon={faEnvelope} /></strong> support@independentindia.in
                  </a>
                </p>
                <p>
                  <a href="https://independentindia.in" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
                    <strong><FontAwesomeIcon icon={faGlobeAsia} /></strong> independentindia.in
                  </a>
                </p>
              </div>
              <div className="col-md-7">
                <form id="contactForm" onSubmit={handleSubmit} noValidate>
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      name="name"
                      className={`form-control ${formErrors.name ? "is-invalid" : ""}`}
                      id="floatingInput01"
                      placeholder="John Doe"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      maxLength="100"
                    />
                    <label htmlFor="floatingInput01">Name<span className="text-danger"> *</span></label>
                    {formErrors.name && <div className="invalid-feedback">{formErrors.name}</div>}
                  </div>

                  <div className="form-floating mb-3">
                    <input
                      type="email"
                      name="email"
                      className={`form-control ${formErrors.email ? "is-invalid" : ""}`}
                      id="floatingInput02"
                      placeholder="johndoe@example.com"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      maxLength="100"
                    />
                    <label htmlFor="floatingInput02">Email address<span className="text-danger"> *</span></label>
                    {formErrors.email && <div className="invalid-feedback">{formErrors.email}</div>}
                  </div>

                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      name="number"
                      className={`form-control ${formErrors.number ? "is-invalid" : ""}`}
                      id="floatingInput03"
                      placeholder="+919876543210"
                      value={formData.number}
                      onChange={handleChange}
                      required
                      maxLength="15"
                    />
                    <label htmlFor="floatingInput03">Contact number<span className="text-danger"> *</span></label>
                    {formErrors.number && <div className="invalid-feedback">{formErrors.number}</div>}
                  </div>

                  <div className="form-floating mb-3">
                    <select
                      name="subject"
                      className={`form-select ${formErrors.subject ? "is-invalid" : ""}`}
                      id="floatingSelect"
                      value={formData.subject}
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled>Select a subject</option>
                      <option value="Get a quote">Get a quote</option>
                      <option value="General Inquiry">General Inquiry</option>
                      <option value="Support Request">Support Request</option>
                      <option value="Product Inquiry">Product Inquiry</option>
                      <option value="Complaint">Complaint</option>
                    </select>
                    <label htmlFor="floatingSelect">Subject<span className="text-danger"> *</span></label>
                    {formErrors.subject && <div className="invalid-feedback">{formErrors.subject}</div>}
                  </div>

                  <div className="form-floating mb-3">
                    <textarea
                      name="comments"
                      className={`form-control ${formErrors.comments ? "is-invalid" : ""}`}
                      id="floatingTextarea"
                      placeholder="Your message..."
                      value={formData.comments}
                      onChange={handleChange}
                      required
                      maxLength="1000"
                      rows="5"
                      style={{ height: '120px' }}
                    />
                    <label htmlFor="floatingTextarea">Message<span className="text-danger"> *</span></label>
                    {formErrors.comments && <div className="invalid-feedback">{formErrors.comments}</div>}
                  </div>

                  {/* Turnstile CAPTCHA */}
                  <div
                    ref={captchaRef}
                    className="captcha-container mb-3 d-flex justify-content-center"
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  ></div>

                  <div className="submit-btn text-center">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={!isFormValid() || isSubmitting}
                    >
                      {isSubmitting ? (
                        <FontAwesomeIcon icon={faCircleNotch} spin />
                      ) : (
                        <>
                          <FontAwesomeIcon icon={faPaperPlane} /> Send Message
                        </>
                      )}
                    </button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;