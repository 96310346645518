import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faSolidStar, faStarHalfStroke as faHalfStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as faRegularStar } from '@fortawesome/free-regular-svg-icons';
import { Carousel } from 'react-bootstrap';

const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [totalReviews, setTotalReviews] = useState(0);
  const [starCounts, setStarCounts] = useState([0, 0, 0, 0, 0]);
  const [responseScore, setResponseScore] = useState(0);
  const [qualityScore, setQualityScore] = useState(0);
  const [deliveryScore, setDeliveryScore] = useState(0);
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const updateRatings = (reviews) => {
    const totalReviews = reviews.length;
    const ratingSum = reviews.reduce((sum, review) => sum + review.rating, 0);
    const averageRating = (ratingSum / totalReviews).toFixed(1);
    setAverageRating(averageRating);
    setTotalReviews(totalReviews);

    const starCounts = [0, 0, 0, 0, 0];
    reviews.forEach((review) => {
      const fullStars = Math.floor(review.rating);
      starCounts[fullStars - 1]++;
    });
    setStarCounts(starCounts.reverse());
  };

  const calculateSatisfaction = (reviews) => {
    const totalReviews = reviews.length;

    const responseScore = (
      (reviews.reduce((sum, review) => sum + review.rating, 0) /
        (totalReviews * 5)) *
      100
    ).toFixed(0);
    const qualityScore = (
      (reviews.reduce((sum, review) => sum + review.rating ** 2, 0) /
        (totalReviews * 25)) *
      100
    ).toFixed(0);
    const deliveryScore = (
      (reviews.filter((review) => review.rating >= 4).length / totalReviews) *
      100
    ).toFixed(0);

    setResponseScore(responseScore);
    setQualityScore(qualityScore);
    setDeliveryScore(deliveryScore);
  };

  const groupReviews = (reviews, groupSize) => {
    let grouped = [];
    for (let i = 0; i < reviews.length; i += groupSize) {
      grouped.push(reviews.slice(i, i + groupSize));
    }
    return grouped;
  };

  useEffect(() => {
    fetch('./assets/uploads/data/reviews.json')
      .then((response) => response.json())
      .then((data) => {
        setReviews(data);
        updateRatings(data);
        calculateSatisfaction(data);
      })
      .catch((error) => console.error('Error fetching reviews data:', error));
  }, []);

  const itemsPerSlide = screenSize >= 768 ? 3 : 1; // Show 3 items per slide on larger screens, 1 on smaller screens
  const groupedReviews = groupReviews(reviews, itemsPerSlide);

  return (
    <section id="reviews" className="content">
      <div className="container">
        <h2>Customer Reviews & Ratings</h2>
        <div className="container my-5 p-4 bg-light rounded" style={{ boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)' }}>
          <div className="row">
            {/* Ratings Summary */}
            <div className="col-md-4 text-center">
              <h4>Ratings & Reviews</h4>
              <div className="display-5">
                <span id="averageRating" className="display-2">{averageRating}</span>/5
              </div>
              <div className="mb-2" id="starRatingSummary">
                {Array.from({ length: 5 }, (_, i) => {
                  if (i < Math.floor(averageRating)) {
                    return <FontAwesomeIcon icon={faSolidStar} className="text-warning h4" key={i} />;
                  } else if (i === Math.floor(averageRating) && averageRating % 1 >= 0.5) {
                    return <FontAwesomeIcon icon={faHalfStar} className="text-warning h4" key={i} />;
                  } else {
                    return <FontAwesomeIcon icon={faRegularStar} className="text-warning h4" key={i} />;
                  }
                })}
              </div>
              <p id="totalReviews" className="text-muted text-center">{totalReviews} Reviews</p>
            </div>

            {/* Star Ratings Breakdown */}
            <div className="col-md-4 px-4" id="starBreakdown">
              {['5', '4', '3', '2', '1'].map((star, index) => (
                <div className="progress-wrapper" key={index}>
                  <div className="progress-label">{star} Star</div>
                  <div className="progress progress-container">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      aria-valuenow={starCounts[index]}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: `${(starCounts[index] / totalReviews) * 100}%` }}
                    ></div>
                  </div>
                  <div className="progress-percentage">
                    {((starCounts[index] / totalReviews) * 100).toFixed(0)}%
                  </div>
                </div>
              ))}
            </div>

            {/* Service Overview */}
            <div className="col-md-4 text-center align-items-center">
              <h5>Service Overview</h5>
              <div className="d-flex flex-wrap justify-content-center mt-3">
                {['Communication', 'Worth', 'Reliability'].map((item, index) => (
                  <div className="col-md-4 col-sm-6 col-12 mb-4" key={index}>
                    <div
                      className="circle-chart mx-auto"
                      style={{
                        '--value': [responseScore, qualityScore, deliveryScore][index],
                        '--size': '5rem',
                        '--thickness': '15px',
                      }}
                    >
                      <strong>
                        {[responseScore, qualityScore, deliveryScore][index]}%
                      </strong>
                    </div>
                    <p className="mt-2 text-center">{item}</p>
                  </div>
                ))}
              </div>
            </div>

          </div>

          {/* User Reviews Carousel */}
          <div className="user-reviews text-center">
            <h2>What do our clients say?</h2>
            <Carousel
              controls={false}
              indicators={false}
              interval={2000}
            >
              {groupedReviews.map((group, index) => (
                <Carousel.Item key={index}>
                  <div className="row">
                    {group.map((review, idx) => (
                      <div className="col-md-4 col-sm-6 col-12 mb-4" key={idx}>
                        <div className="card shadow-sm h-100 review-card">
                          <div className="card-body">
                            {/* User Name and Location */}
                            <div className="d-flex align-items-center mb-3 text-start">
                              <img
                                src={review.image}
                                className="rounded-circle me-3"
                                alt="User"
                                height="50px"
                                width="50px"
                              />
                              <div>
                                <h6 className="mb-0">{review.name}</h6>
                                <small className="text-muted">{review.location}</small>
                              </div>
                            </div>

                            {/* Star Rating */}
                            <div className="rating mb-2 d-flex justify-content-start text-start">
                              {Array.from({ length: 5 }, (_, i) => {
                                if (i < Math.floor(review.rating)) {
                                  return <FontAwesomeIcon icon={faSolidStar} className="text-warning h4" key={i} />;
                                } else if (i === Math.floor(review.rating) && review.rating % 1 >= 0.5) {
                                  return <FontAwesomeIcon icon={faHalfStar} className="text-warning h4" key={i} />;
                                } else {
                                  return <FontAwesomeIcon icon={faRegularStar} className="text-warning h4" key={i} />;
                                }
                              })}
                            </div>

                            {/* Review Text */}
                            <p className="card-text text-start">{review.review}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reviews;