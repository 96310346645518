import React from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";

const LocalBusinessSchema = () => {
  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "INDEPENDENT INDIA",
    "url": "https://www.independentindia.in/",
    "logo": "https://www.independentindia.in/favicon.svg",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "08100991357",
      "contactType": "customer service",
      "areaServed": "IN",
      "availableLanguage": ["en", "Hindi", "Bengali"]
    },
    "sameAs": [
      "https://www.independentindia.in/",
      "https://www.facebook.com/people/Independent-India/61565495714203/",
      "https://www.instagram.com/independentindia.in"
    ]
  };

  return (
    <HelmetProvider>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
      </Helmet>
    </HelmetProvider>
  );
};

export default LocalBusinessSchema;